export function enableShareSocialBtns(): void {
    const socialBtns: HTMLCollectionOf<Element> =document.getElementsByClassName('social-share')

    for (const btn of Array.from(socialBtns)) {
        btn.addEventListener('click', (e: MouseEvent): void => {
            e.preventDefault()
            const element: HTMLElement = btn as HTMLElement

            if (element.dataset.source === 'blog-post') {
                const title: string = document.getElementsByTagName('title')[0]?.textContent.trim()
                openSocialShare(element.dataset.network, title)
            }
        })
    }
}

function openSocialShare(network: string, title: string, linkToShare: string = window.location.href): void {
    let socialLink

    switch (network) {
        case 'fb':
            socialLink = `http://www.facebook.com/share.php?title=${encodeURIComponent(title)}&u=`
            window.open(socialLink + encodeURIComponent(linkToShare), 'window', 'width=640,height=480,resizable,scrollbars')
            break
        case 'twitter':
            socialLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=`
            window.open(socialLink + encodeURIComponent(linkToShare), 'window', 'width=640,height=480,resizable,scrollbars')
            break
        case 'linkedin':
            socialLink = `https://www.linkedin.com/sharing/share-offsite/?url=${linkToShare}`
            window.open(socialLink, 'window', 'width=640,height=480,resizable,scrollbars')
            break
    }
}
