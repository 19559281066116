import {
    validate,
    VALIDATOR_REQUIRE,
    VALIDATOR_MINLENGTH,
    VALIDATOR_MAXLENGTH,
    VALIDATOR_ALPHABETIC,
    VALIDATOR_EMAIL, VALIDATOR_PHONE,
    VALIDATOR_ALPHABETIC_ALPHANUMERIC, VALIDATOR_ALPHABETIC_COUNT
} from '../utils/validators'

try {
	const openFormBtnB2C: Element = document.getElementById('course-form-b2c')

	openFormBtnB2C?.addEventListener('click', (event): void => {
		let record = JSON.parse((event.target as HTMLElement)?.getAttribute('data-course'));
		record = prepareCourseData(record);
		const popup = PopupForm.getInstance('form-b2c', '/getformb2c', {
			title: 'Sign Up',
			subtitle: generateCourseSubtitle(record),
			record: record
		});
		popup.show();
	})
} catch (error) {
	console.error(error)
}

interface Course {
	id: number;
	code?: string;
	title?: string;
	datefrom?: string;
	dateto?: string;
	duration?: string;
	time?: string;
	price?: number;
	currency?: string;
	labels?: Object;
	lang?: string;
	type?: string;
}

/**
 * Prepare course data for web-form
 * @returns Course
 */
function prepareCourseData(record: Course): Course {
	const courseSessions: Element = document.getElementById('course-sessions');
	if (record && courseSessions) {
		try {
			const selectedSession = JSON.parse((<HTMLInputElement>courseSessions.querySelector('input[name="session"]:checked'))?.value);
			if (selectedSession) {
				record = { ...record, ...selectedSession };
			}
		} catch (error) {
			console.error(error);
		}
	}
	return record;
}

/**
 * Collect course data for popup subtitle
 * @param course
 * @returns
 */
function generateCourseSubtitle(course: Course): string {
	let subtitle = '<div>';
	if (course.code) {
		subtitle += `[${course.code.trim()}]`;
	}
	if (course.title) {
		subtitle += ` "${course.title}"`;
	}
	subtitle += '</div>';
	if (course.datefrom && course.dateto) {
		subtitle += `(${course.datefrom} - ${course.dateto}`;
		if (course.time) {
			subtitle += `|${course.time}`;
		}
		subtitle += `)`;
	}
	if (course.price) {
		subtitle += ` - ${course.price}`;

		if (course.currency) {
			subtitle += typeof course.currency === "object" ? '€' : `${course.currency}`;
		}
	}

	if (course.labels) {
		subtitle += `<ul class="labels">`;
		for (const code in course.labels) {
			if (Object.prototype.hasOwnProperty.call(course.labels, code)) {
				let label = course.labels[code];
				if (!label || label instanceof Object) continue;
				let icon = '';
				switch (code) {
					case 'duration':
						label += ' hours';
						icon = `<img width="20" height="20" src="/theme/luxoft-2024/assets/images/icons/clock.svg" alt="${label}"/>`
						break;
					case 'format':
						icon = `<img width="20" height="20" src="/theme/luxoft-2024/assets/images/icons/format.svg" alt="${label}"/>`;
						break;
					case 'language':
						icon = `<img width="20" height="20" src="/theme/luxoft-2024/assets/images/icons/language.svg" alt="${label}"/>`;
						break;
					default:
						break;
				}
				subtitle += `<li class="label label--${code}">
						<span class="label-icon">
							${icon}
						</span>
						<span class="label-text">
							${label}
						</span>
					</li>`;
			}
		}
		subtitle += `</ul>`;
	}
	return subtitle;
}

/**
 * PopupForm class create popup form with dynamic content
 */
export class PopupForm {
	private static instances: { [key: string]: PopupForm } = {};

	private id: string;
	private url: string;
	private params: any;
	private overlay: HTMLElement | null = null;
	private modal: HTMLElement | null = null;
	private isLoading: boolean = false;
	private isInitialized: boolean = false;
	private fieldRules = {
		sign_up_name: {
            validators: [VALIDATOR_REQUIRE(), VALIDATOR_ALPHABETIC_COUNT(3), VALIDATOR_ALPHABETIC('Name'), VALIDATOR_MINLENGTH(3), VALIDATOR_MAXLENGTH(30)],
            is_required: true
		},
		sign_up_company: {
            validators: [VALIDATOR_REQUIRE(), VALIDATOR_ALPHABETIC_COUNT(2), VALIDATOR_ALPHABETIC_ALPHANUMERIC(), VALIDATOR_MINLENGTH(2), VALIDATOR_MAXLENGTH(30)],
            is_required: true
		},
		sign_up_email: {
            validators: [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL(), VALIDATOR_MAXLENGTH(40)],
            is_required: true
		},
		sign_up_phone: {
            validators: [VALIDATOR_PHONE(), VALIDATOR_MAXLENGTH(20)],
            is_required: false
		},
		sign_up_comment: {
            validators: [VALIDATOR_REQUIRE(), VALIDATOR_ALPHABETIC_COUNT(18), VALIDATOR_MINLENGTH(20), VALIDATOR_MAXLENGTH(250)],
            is_required: true
		}
	}

	private constructor (id: string, url: string, params: any) {
		this.id = id;
		this.url = url;
		this.params = params;
		this.initialize();
	}

	private initialize() {
		if (!this.overlay) {
			this.overlay = this.createOverlay();
			document.body.appendChild(this.overlay);
		}

		if (!this.modal) {
			this.modal = this.createModalStructure();
			document.body.appendChild(this.modal);
		}
	}

	private createOverlay(): HTMLElement {
		const overlay = this.createElement('div', { class: 'overlay', id: 'overlay' });
		overlay.addEventListener('click', () => this.close());
		return overlay;
	}

	private createModalStructure(): HTMLElement {
		const modal = this.createElement('div', { id: this.id, class: 'modal', tabindex: '-1', role: 'dialog' });
		const modalDialog = this.createElement('div', { class: 'modal-dialog', role: 'document' });
		const modalContent = this.createElement('div', { class: 'modal-content' });
		const modalHeader = this.createElement('div', { class: 'modal-header' });
		const modalTitle = this.createElement('h5', { class: 'modal-title' }, this.params.title);
		const modalSubTitle = this.createElement('div', { class: 'modal-subtitle' }, this.params.subtitle);
		const closeButton = this.createElement('button', { type: 'button', class: 'modal-btn--close', 'aria-hidden': 'true', 'data-dismiss': 'modal', 'aria-label': 'Close' }, `
			<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M10.668 10.6667L21.3346 21.3334" stroke="#63666A" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
				<path d="M21.3346 10.6667L10.668 21.3334" stroke="#63666A" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
			</svg>
		`);

		closeButton.addEventListener('click', () => this.close());
		modal.addEventListener('click', (event) => {
			const node = event.target;
			if (node instanceof HTMLElement && !modalDialog.contains(node) && modal.contains(node)) {
				this.close();
			}
		});

		modalHeader.appendChild(modalTitle);
		modalHeader.appendChild(modalSubTitle);
		modalHeader.appendChild(closeButton);

		const modalBody = this.createElement('div', { class: 'modal-body' }, `<div class="text-center"><div class="spinner-border" role="status"><span class="sr-only"></span></div></div>`);

		modalContent.appendChild(modalHeader);
		modalContent.appendChild(modalBody);

		modalDialog.appendChild(modalContent);
		modal.appendChild(modalDialog);
		return modal;
	}

	private createElement(tag: string, attributes: { [key: string]: string }, htmlContent?: string): HTMLElement {
		const element = document.createElement(tag);
		for (const attr in attributes) {
			element.setAttribute(attr, attributes[attr]);
		}
		if (htmlContent) {
			element.innerHTML = htmlContent;
		}
		return element;
	}

	private async fetchData() {
		if (this.isLoading) return;
		this.isLoading = true;

		try {
			const response = await fetch(this.url, {
				method: 'POST',
				body: JSON.stringify(this.params),
				headers: {
					'Content-Type': 'application/json'
				}
			});

			if (!response.ok) {
				throw new Error("Loading form error: " + response.statusText);
			}

			const data = await response.text();
			this.updateModalContent(data);
		} catch (error) {
			alert('Loading form error: ' + error.message);
			throw error;
		} finally {
			this.isLoading = false;
		}
	}

	private updateModalContent(content: string) {
		if (this.modal) {
			const modalBody = this.modal.querySelector('.modal-body');
			if (modalBody) {
				modalBody.innerHTML = this.cleanContent(content);
				this.executeScripts(modalBody);
				this.addFormSubmitListeners(modalBody);
                this.enableLiveValidations(modalBody)
			}
		}
	}

	private showErrorMessage(field: HTMLElement, message: string = ''): void {
		const errorContainer: HTMLElement = document.getElementById(`form-${field.id}-error`)
		if (errorContainer) {
            const previousSibling: HTMLElement = errorContainer.previousElementSibling as HTMLElement

            if (previousSibling) {
                previousSibling.classList.add('with-error')
            }

            if (message) {
                errorContainer.querySelector("p").textContent = message
            }

            errorContainer.classList.remove('hidden')
		}
	}

	private hideErrorMessage(field: HTMLElement): void {
		const errorContainer: HTMLElement = document.getElementById(`form-${field.id}-error`)
		if (errorContainer) {
			errorContainer.classList.add('hidden')

            const previousSibling: HTMLElement = errorContainer.previousElementSibling as HTMLElement

            if (previousSibling) {
                previousSibling.classList.remove('with-error')
            }
		}
	}

	private validateInputs(allInputs: NodeListOf<Element>[], source: string | null = null): boolean | void {
		let isValid: boolean = true
		const $that = this
		let validationResults: {} = {}
        let keyPressed: boolean = false

		for (const inputs of allInputs) {
			inputs.forEach(function (field: HTMLInputElement): void {
                if (source === 'submit') {
					if (field?.id !== "g-recaptcha-response") {
						const { inputIsValid, message} = validate(field.value.trim(), $that.fieldRules[field.id].validators)
						validationResults = { ...validationResults, ...{ [field.id]: inputIsValid }}

						if (!inputIsValid) {
							$that.showErrorMessage(field, message)
						} else {
							$that.hideErrorMessage(field)
						}

						if (!$that.fieldRules[field.id].is_required && field.value.trim() === '') {
							$that.hideErrorMessage(field)
							validationResults[field.id] = true
						}

						for (const [key, value] of Object.entries(validationResults)) {
							if (!value) {
								isValid = false
							}
						}
					}
                } else {
                    field.addEventListener('keyup', function (e: KeyboardEvent): void {
                        if (!keyPressed && e.key !== 'Tab') {
                            keyPressed = true

                            setTimeout(() => {
                                const { inputIsValid, message} = validate(this.value.trim(), $that.fieldRules[field.id].validators)

                                if (!inputIsValid) {
                                    $that.showErrorMessage(field, message)
                                } else {
                                    $that.hideErrorMessage(field)
                                }

                                if (!$that.fieldRules[field.id].is_required && this.value.trim() === '') {
                                    $that.hideErrorMessage(field)
                                }

                                keyPressed = false
                            }, 1500)
                        }
                    })

                    field.addEventListener('blur', function (): void {
                        this.value = this.value.trim()

                        if ($that.fieldRules[field.id].is_required && this.value.trim() === '') {
                            $that.showErrorMessage(field, 'This field is required.')
                        }
                    })
                }
			})
		}

        if (source === 'submit') {
            return isValid
        }
	}

	private validateForm(form: HTMLFormElement, source: string | null = null): boolean | void {
		const textFields: NodeListOf<Element> = form.querySelectorAll('input[type="text"]')
		const emailField: NodeListOf<Element> = form.querySelectorAll('input[type="email"]')
		const phoneField: NodeListOf<Element> = form.querySelectorAll('input[type="tel"]')
		const messageField: NodeListOf<Element> = form.querySelectorAll('textarea')

        if (source === 'submit') {
            return this.validateInputs([textFields, emailField, phoneField, messageField], source)
        }

        this.validateInputs([textFields, emailField, phoneField, messageField])
	}

    private enableLiveValidations(container: Element): void {
        const form: HTMLFormElement = container.querySelectorAll('form')[0]
        if (form) {
            this.validateForm(form)
        }
    }

	private addFormSubmitListeners(container: Element): void {
		const form: HTMLFormElement = container.querySelectorAll('form')[0]
		const notesNotes = document.getElementById('form-btn-collapse')
		const explanatoryNotesContent: HTMLElement = document.getElementById('explanatory-notes-content')

		if (
			notesNotes &&
			explanatoryNotesContent
		) {
			notesNotes.addEventListener('click', function(e: MouseEvent): void {
				e.preventDefault()
				this.classList.toggle('expanded')
				explanatoryNotesContent.classList.toggle('expanded')
			})
		}

		if (form) {
			form.addEventListener('submit', async (e: SubmitEvent) => {
				e.preventDefault()
				const isValidForm: boolean = this.validateForm(form, 'submit') as boolean
				const formData: FormData = new FormData(form as HTMLFormElement)

				if (isValidForm) {
					try {
						const response: Response = await fetch(this.url, {
							method: 'POST',
							body: formData,
						});

						if (!response.ok) {
							throw new Error("Form submission error: " + response.statusText);
						}

						if (response.ok) {
							const data: string = await response.text();
							this.updateModalContent(data);
						}
					} catch (error) {
						alert('Form submission error: ' + error.message);
					}
				}
			})
		}

	}

	private executeScripts(container: Element) {
		const scripts = container.querySelectorAll('script');
		scripts.forEach(script => {
			const src = script.getAttribute('src');
			if (src) {
				const newScript = document.createElement('script');
				newScript.src = src;
				document.body.appendChild(newScript);
			} else {
				const newScript = document.createElement('script');
				newScript.textContent = script.textContent;
				document.body.appendChild(newScript);
			}
		});
	}

	private cleanContent(content: string): string {
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = content;

		const header = tempDiv.querySelector('header');
		const footer = tempDiv.querySelector('footer');

		if (header) header.remove();
		if (footer) footer.remove();

		return tempDiv.innerHTML;
	}

	public open() {
		if (this.modal) {
			this.modal.classList.add('show');
		}
		if (this.overlay) {
			this.overlay.classList.add('show');
		}
	}

	public close() {
		if (this.modal) {
			this.modal.classList.remove('show');
		}
		if (this.overlay) {
			this.overlay.classList.remove('show');
		}
	}

	public async show() {
		if (!this.isInitialized) {
			this.open();
			this.fetchData();
			this.isInitialized = true;
		} else {
			this.open();
		}
	}

	public static getInstance(id: string, url: string, params: any): PopupForm {
		const key = `${id}-${JSON.stringify(params)}`;
		if (!this.instances[key]) {
			this.instances[key] = new PopupForm(id, url, params);
		}
		return this.instances[key];
	}
}
