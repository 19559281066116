const openMobileBtn: Element = document.getElementById('mobile-menu-switcher')
const mobileMenu: Element = document.getElementById('mobile-menu')
const mOverlay: Element = document.getElementById('mobile-overlay')
const closeMobileBtn: Element = document.getElementById('mobile-menu-close')

openMobileBtn.addEventListener('click', (): void => {
    mobileMenu.classList.toggle('menu-open')
    mOverlay.classList.toggle('show')
})

mOverlay.addEventListener('click', (): void => {
    mobileMenu.classList.toggle('menu-open')
    mOverlay.classList.toggle('show')
})

closeMobileBtn.addEventListener('click', (): void => {
    mobileMenu.classList.toggle('menu-open')
    mOverlay.classList.toggle('show')
})
