<script setup>
import { onMounted, ref, computed } from 'vue'
import useFetchCalls from "../functions/apiCalls";
const searchWord = ref('')
const categories = ref([])
const keywords = ref([])
const keywordSearchPanel = ref(null)
const keywordSearchInput = ref(null)

const {
    getCoursesSearchPrompts
} = useFetchCalls()

const openKeywordSearchMobile = () => {
    if (window.innerWidth <= 1023) {
        keywordSearchPanel.value.classList.add('show')
        keywordSearchInput.value.focus()

        setTimeout(() => {
            if (keywordSearchPanel.value.getBoundingClientRect().top !== 0) {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }
        }, 300)
    }
}

const submitFormHandler = () => {
    let urlWithKeyword =  `/search`

    if (searchWord.value.trim() !== '') {
        urlWithKeyword += `?keyword=${searchWord.value.trim().replace(/#/g, '%23').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/,/g, '%2C').replace(/\//g, '%2F').replace(/\./g, '%2E').replace(/\+/g, '%2B')}`
    }

    window.location.href = urlWithKeyword
    searchWord.value = ''
}

const getAllCoursesSearchPrompts = async () => {
    const res = await getCoursesSearchPrompts()

    if (res?.data) {
        categories.value = res.data.categories
        keywords.value = res.data.keywords
    }
}

onMounted(() => {
    getAllCoursesSearchPrompts()
})

const foundCategories = computed( () => {
    return categories.value.filter(item => item.toLowerCase().includes(searchWord.value.toLowerCase()) && searchWord.value.trim() !== '')
})

const foundKeywords = computed( () => {
    return keywords.value.filter(item => item.toLowerCase().includes(searchWord.value.toLowerCase()) && searchWord.value.trim() !== '')
})

const handleItemStructure = (str) => {
    const itemStructure = str.split(' ')
    const searchWordStructure = searchWord.value.trim().toLowerCase().split(' ')

    return itemStructure.map(part => {
        let isPartBold = false
        for (const word of searchWordStructure) {

            if (
                word.length >= 4 &&
                part.toLowerCase().includes(word)
            ) {
                isPartBold = true
                break
            }
        }

        return isPartBold ? `<b>${part}</b>` : part
    }).join(' ')
}
</script>

<template>
    <div class="homepage__search-section--wrapper">
        <form
            class="search-form"
            :class="{ 'showing-results': searchWord.trim() !== '' }"
            @submit.prevent="submitFormHandler"
        >
            <img src="/theme/luxoft-2024/assets/images/icons/search-gray.svg" class="search-form--icon" alt="search icon">
            <label for="search-word"></label>
            <input class="search-form--input" v-model="searchWord" @focus="openKeywordSearchMobile" placeholder="What kind of training are you looking for...?" name="search-word">
            <button type="submit" class="btn-normal text-uppercase">Search</button>
        </form>
        <div class="d-flex align-items-center popular-search">
            <p class="popular-search__label">Popular search:</p>
            <div class="popular-search__role"><a href="/category/software-architecture-and-design">Software architecture</a></div>
            <div class="popular-search__role"><a href="/category/java">Java</a></div>
            <div class="popular-search__role"><a href="/category/management">PM</a></div>
            <div class="popular-search__role"><a href="/category/scripting-languages">Python</a></div>
            <div class="popular-search__role"><a href="/category/testing">QA</a></div>
            <div class="popular-search__role"><a href="/category/analysis">Business & System Analysis </a></div>
        </div>
    </div>
    <div
        class="home-search-results"
        :class="{ 'active': searchWord.trim() !== '' }"
    >
        <div class="home-search-results__wrapper">
            <template v-if="foundCategories.length > 0 || foundKeywords.length > 0">
                <template v-for="item in foundCategories">
                    <a :href="`/search?keyword=&category[]=${item}`" class="result-item">
                        <img src="/theme/luxoft-2024/assets/images/icons/search-gray.svg" alt="search icon">
                        <template v-if="searchWord.trim().length >= 4">
                            <p v-html="handleItemStructure(item.trim())" />
                        </template>
                        <template v-else>
                            <p>{{ item }}</p>
                        </template>
                    </a>
                </template>
                <template v-for="item in foundKeywords">
                    <a :href="`/search?keyword=${item}`" class="result-item">
                        <img src="/theme/luxoft-2024/assets/images/icons/search-gray.svg" alt="search icon">
                        <template v-if="searchWord.trim().length >= 4">
                            <p v-html="handleItemStructure(item.trim())" />
                        </template>
                        <template v-else>
                            <p>{{ item }}</p>
                        </template>
                    </a>
                </template>
            </template>
            <template v-else>
                <div class="nothing-found">
                    <img src="/theme/luxoft-2024/assets/images/icons/nothing-found.svg" alt="nothing-found icon">
                    <p>Nothing found, change the search parameters</p>
                </div>
            </template>
        </div>
    </div>

    <Teleport to="body">
        <div class="keyword-search-panel" ref="keywordSearchPanel">
            <div class="keyword-search-panel__input-section mt-3">
                <button @click="keywordSearchPanel.classList.remove('show')" class="back-btn">
                    <img src="/theme/luxoft-2024/assets/images/icons/gray-arrow.svg" alt="gray-arrow icon">
                </button>

                <form
                    class="filter-input-search"
                    @submit.prevent="submitFormHandler"
                >
                    <img src="/theme/luxoft-2024/assets/images/icons/search-gray.svg" class="search-icon" alt="search icon">
                    <input type="text" name="searchWord" placeholder="What kind of training are you looking for...?" v-model="searchWord" ref="keywordSearchInput">
                    <button type="button" @click="searchWord = ''">
                        <img src="/theme/luxoft-2024/assets/images/icons/close-chip.svg" class="close-search" alt="close search icon">
                    </button>
                </form>

            </div>
            <div class="keyword-search-panel__results mt-2 home-search">
                <ul v-if="foundCategories.length > 0 || foundKeywords.length > 0">
                    <li v-for="item in foundCategories">
                        <a :href="`/search?keyword=&category[]=${item}`">
                            <p>{{ item }}</p>
                        </a>
                    </li>
                    <li v-for="item in foundKeywords">
                        <a :href="`/search?keyword=${item}`">
                            <p>{{ item }}</p>
                        </a>
                    </li>
                </ul>
                <div v-if="foundCategories.length === 0 && foundKeywords.length === 0 && searchWord !== ''" class="nothing-found">
                    <img src="/theme/luxoft-2024/assets/images/icons/nothing-found.svg" alt="nothing-found icon">
                    <p>Nothing found, change the search parameters</p>
                </div>
            </div>
        </div>
    </Teleport>
</template>
