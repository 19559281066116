document.addEventListener('DOMContentLoaded', function (): void {
    require('./scripts/aos-animation')
    require('./scripts/header')
    require('./scripts/menu')
    require('./scripts/course')
    require('./scripts/hidden-tags')
    require('./scripts/blog-post')
    require('./scripts/page-sections')
    require('./scripts/scroll-to-section')
    require('./scripts/seo')
    require('./scripts/contact-us-form')
    require('./scripts/about-us')
    require('./scripts/carousel-speakers')
}, false)
