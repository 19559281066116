const header: HTMLElement = document.getElementById('header')
const whiteSection: HTMLElement = document.getElementById('whiteSection')
const blogPostHeader: HTMLElement = document.getElementById('blog-post-mobile-header')
const relatedContentRightColumn: HTMLElement = document.getElementById('related-content-right-column')
const authorsContainer: HTMLElement = document.querySelector('.blog-post__right-column .blog-post__right-column--authors')
const relatedContentBottom: HTMLElement = document.querySelector('.blog-post__related-content')

let lastScrollTop: number = 0

function handleScrollPo(): void {
    if (window.innerWidth >= 1024) {
        let whiteSectionRect: DOMRect = whiteSection?.getBoundingClientRect()

        if (header) {
            if (whiteSectionRect && whiteSectionRect.top < 0) {
                header.classList.add('bg-white')
                header.classList.remove('text-white')
                header.classList.add('text-black')
                header.style.position = 'fixed'
            } else {
                header.classList.remove('bg-white')
                header.classList.remove('text-black')
                header.classList.add('text-white')
                header.style.position = 'absolute'
                header.style.top = '0'
                header.classList.remove('shadow')
            }
        }
    }

    if (blogPostHeader) {
        if (window.pageYOffset > 215) {
            blogPostHeader.classList.add('fixed')
        } else {
            blogPostHeader.classList.remove('fixed')
        }
    }

    if (relatedContentRightColumn) {
        let scrollFromTop: number = window.pageYOffset || document.documentElement.scrollTop

        if (
            relatedContentRightColumn.getBoundingClientRect().top <= 175 &&
            scrollFromTop > lastScrollTop // scrolling down
        ) {
            relatedContentRightColumn.classList.add('fixed')
        }

        if (
            (window.pageYOffset - authorsContainer.offsetHeight) < 10 &&
            scrollFromTop < lastScrollTop // // scrolling up
        ) {
            relatedContentRightColumn.classList.remove('fixed')
        }

        lastScrollTop = scrollFromTop <= 0 ? 0 : scrollFromTop

        if (relatedContentBottom) {
            if ((relatedContentBottom.getBoundingClientRect().top - relatedContentRightColumn.offsetHeight) <= 200) {
                if (!relatedContentRightColumn.classList.contains('scroll')) {
                    relatedContentRightColumn.style.marginTop = `${window.pageYOffset - authorsContainer.offsetHeight}px`
                }

                relatedContentRightColumn.classList.add('scroll')
                relatedContentRightColumn.classList.remove('fixed')
            }
        }

        if (relatedContentRightColumn) {
            if (
                relatedContentRightColumn.getBoundingClientRect().top >= 148 &&
                relatedContentRightColumn.classList.contains('scroll')
            ) {
                relatedContentRightColumn.style.marginTop = '0'
                relatedContentRightColumn.classList.add('fixed')
                relatedContentRightColumn.classList.remove('scroll')
            }
        }
    }
}

document.addEventListener('scroll', handleScrollPo)
handleScrollPo()
