import HomeSearch from "../vue/components/HomeSearch.vue"
import BlogFilters from "../vue/components/BlogFilters.vue"
import CoursesFilter from "../vue/components/CoursesFilter.vue"

export const routes = {
  "/": {
    name: "HomeSearch",
    component: HomeSearch,
  },
  "/blog": {
    name: "BlogFilters",
    component: BlogFilters,
  },
  "/blog/page/": {
    regex: /^\/blog\/page\/\d+$/,
    name: "BlogFilters",
    component: BlogFilters,
  },
  "/it-courses": {
    name: "CoursesFilter",
    component: CoursesFilter,
  },
  "/it-courses/page/": {
    regex: /^\/it-courses\/page\/\d+$/,
    name: "CoursesFilter",
    component: CoursesFilter,
  },
}

export const getCurrentPathComponent = (routes, path = window.location.pathname) => {
  const route = routes[path]

  if (route) {
    return {...route}
  }

  for (let index in routes) {
    if (!routes[index]?.regex) {
      continue
    }

    if (window.location.pathname.match(routes[index].regex) === null) {
      continue
    }

    return { name: routes[index].name, component: routes[index].component }
  }

  return {}
}
