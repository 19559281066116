const tagsList: HTMLCollectionOf<Element> = document.getElementsByClassName('tag__list');

for (let i = 0; i < tagsList.length; i++) {
    const tagList = tagsList[i];
    const showItems = parseInt(tagList.getAttribute('data-show')) - 1;
    if (showItems > 0) {
        const tags = tagList.children;
        if (tags.length > showItems) {
            let hidden = 0;
            for (let i = showItems; i < tags.length; i++) {
                tags[i].classList.add('d-none');
                hidden++;
            }
            const showMore = tags[showItems - 1].cloneNode(true) as HTMLElement;
            showMore.classList.add('show-more');
            showMore.innerHTML = `Show +${hidden} more`;
            showMore.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                for (let i = showItems; i < tags.length; i++) {
                    tags[i].classList.remove('d-none');
                }
                showMore.remove();
            });
            tagList.appendChild(showMore);
        }
    }
}
