const useFetchCalls = () => {

  const getCoursesSearchPrompts = async () => {
    try {
      const response = await fetch('/ajax/get-courses-search-prompts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (!response) {
        throw new Error('Could not load the elements')
      }

      return await response.json()
 
    } catch (e) {
      console.log(e)
    }
  }

  return {
    getCoursesSearchPrompts,
  }
}

export default useFetchCalls
