const VALIDATOR_TYPE_REQUIRE: string = 'REQUIRE'
const VALIDATOR_TYPE_MINLENGTH: string = 'MINLENGTH'
const VALIDATOR_TYPE_MAXLENGTH: string = 'MAXLENGTH'
const VALIDATOR_TYPE_EMAIL: string = 'EMAIL'
const VALIDATOR_TYPE_PHONE: string = 'PHONE'
const VALIDATOR_TYPE_ALPHABETIC: string = 'ALPHABETIC'
const VALIDATOR_TYPE_ALPHABETIC_ALPHANUMERIC: string = 'ALPHABETIC_ALPHANUMERIC'
const VALIDATOR_TYPE_ALPHABETIC_COUNT: string = 'ALPHABETIC_COUNT'

export const VALIDATOR_ALPHABETIC_ALPHANUMERIC = (): {type: string} => ({ type: VALIDATOR_TYPE_ALPHABETIC_ALPHANUMERIC })
export const VALIDATOR_REQUIRE = (): {type: string} => ({ type: VALIDATOR_TYPE_REQUIRE })
export const VALIDATOR_ALPHABETIC = (fieldName: string): {type: string, field: string} => ({
    type: VALIDATOR_TYPE_ALPHABETIC,
    field: fieldName
})
export const VALIDATOR_EMAIL = (): {type: string} => ({ type: VALIDATOR_TYPE_EMAIL })
export const VALIDATOR_PHONE = (): {type: string} => ({ type: VALIDATOR_TYPE_PHONE })
export const VALIDATOR_MINLENGTH = (val: number): {type:string, val: number} => ({
  type: VALIDATOR_TYPE_MINLENGTH,
  val: val
})
export const VALIDATOR_ALPHABETIC_COUNT = (val: number): {type:string, val: number} => ({
    type: VALIDATOR_TYPE_ALPHABETIC_COUNT,
    val: val
})

export const VALIDATOR_MAXLENGTH = (val: number): {type:string, val: number} => ({
  type: VALIDATOR_TYPE_MAXLENGTH,
  val: val
})

function isAlphabeticAndAlphanumeric(str: string): boolean {
    const regex: RegExp = /^[A-Za-z\s0-9-]+$/
    return regex.test(str)
}
function isAlphabetic(str: string): boolean {
    const regex: RegExp = /^[A-Za-z\s-]+$/
    return regex.test(str)
}

function isValidEmail(str: string): boolean {
    const regex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return regex.test(str)
}

function isValidPhone(str: string): boolean {
    const regex: RegExp = /^[0-9]*\+?[0-9-]*$/
    return regex.test(str)
}

function hasDigitsEnough(str: string): boolean {
    // Remove all non-digit characters
    const onlyNumbers: string = str.replace(/\D/g, '')
    return onlyNumbers.length >= 10
}

function countAlphabeticChars(str: string): number {
    const matches: RegExpMatchArray = str.match(/[a-zA-Z]/g)
    return matches ? matches.length : 0
}

export const validate = (value: string, validators: any[]): {inputIsValid: boolean, message: string} => {
    let inputIsValid: boolean = true
    let message: string = ''

    for (const validator of validators) {
        if (validator.type === VALIDATOR_TYPE_REQUIRE && value.length <= 0 ) {
          inputIsValid = false
          message = 'This field is required.'
        }
        if (validator.type === VALIDATOR_TYPE_ALPHABETIC && !isAlphabetic(value) ) {
          inputIsValid = false
          message = `${validator.field} contains invalid characters. Please use only Latin letters (A-Z), hyphen symbol and spaces.`
        }
        if (validator.type === VALIDATOR_TYPE_ALPHABETIC_COUNT && countAlphabeticChars(value) < validator.val ) {
            inputIsValid = false
            message = `The field should contains at lest ${validator.val} Latin letters (A-Z).`
        }
        if (validator.type === VALIDATOR_TYPE_ALPHABETIC_ALPHANUMERIC && !isAlphabeticAndAlphanumeric(value) ) {
          inputIsValid = false
          message = 'Invalid format (only Latin letters, numbers, spaces and hyphen symbol are allowed).'
        }
        if (validator.type === VALIDATOR_TYPE_MINLENGTH && value.length < validator.val) {
          inputIsValid = false
          message = `The field must be at least ${validator.val} characters long.`
        }
        if (validator.type === VALIDATOR_TYPE_MAXLENGTH && value.length > validator.val) {
          inputIsValid = false
          message = `The field cannot exceed ${validator.val} characters.`
        }
        if (validator.type === VALIDATOR_TYPE_EMAIL && !isValidEmail(value)) {
          inputIsValid = false
          message = 'It is not a valid email.'
        }
        if (validator.type === VALIDATOR_TYPE_PHONE && !hasDigitsEnough(value)) {
            inputIsValid = false
            message = 'Please enter at least 10 digits.'
        }
        if (validator.type === VALIDATOR_TYPE_PHONE && !isValidPhone(value)) {
            inputIsValid = false
            message = 'Invalid format (only numbers, minus symbol, and one plus symbol are allowed).'
        }
  }

    return {
        inputIsValid,
        message
    }
}
