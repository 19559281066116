<template>
    <div>
        <div
            ref="overlayMobile"
            class="overlay overlay-mobile"
            @click="closePanel"
        ></div>
        <div class="container courses-filter-chips-wrapper">
            <div class="courses-filter">
                <form @submit.prevent="applyFilters" class="courses-filter__categories-form">
                    <div class="dropdown" :class="checkedCategories.length > 0 ? 'items-checked' : ''">
                        <button
                            class="dropdown-face body-m-regular text-medium-gray"
                            type="button"
                            id="categoriesDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            @click="openMobileFilters"
                        >
                            Category
                            <img src="/theme/luxoft-2024/assets/images/icons/caret-down-black.svg" width="20" height="24" alt="caret down icon">
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="categoriesDropdown">
                            <li v-for="(topic, key) in allCategories">
                                <label :for="topic.id">{{ topic.title }}</label>
                                <input type="checkbox" :id="topic.id" :value="topic.title" v-model="checkedCategories">
                            </li>
                        </ul>
                    </div>

                    <button type="submit" class="btn-normal text-uppercase">
                        Apply
                    </button>
                </form>
                <form class="courses-filter__search-form" @submit.prevent="applyFilters">
                    <label for="search-word"></label>
                    <input type="text" placeholder="Search" name="search-word" v-model="keyword">
                    <button type="submit" class="btn-normal">
                        <img src="/theme/luxoft-2024/assets/images/icons/magnifier.svg" width="24" height="24" alt="search icon">
                    </button>
                </form>
            </div>

            <div class="courses-filter__chips">
                <div
                    v-if="keywordCopyForChip"
                    class="courses-filter__chips courses-filter__chips--chip"
                >
                    <p>{{ keywordCopyForChip }}</p>
                    <button @click="removeCheckedItem('keyword')">
                        <img src="/theme/luxoft-2024/assets/images/icons/close-purple.svg" width="24" height="24" alt="close chip icon">
                    </button>
                </div>

                <template v-for="(topic, index) in checkedCategoriesCopyForChips">
                    <div class="courses-filter__chips courses-filter__chips--chip">
                        <p :class="{ 'bigger-tag': bigTagsNames.includes(topic) }">{{ topic }}</p>
                        <button @click="(event) => removeCheckedItem('category', index)">
                            <img src="/theme/luxoft-2024/assets/images/icons/close-purple.svg" width="24" height="24" alt="close search icon">
                        </button>
                    </div>
                </template>

                <template v-for="(tag, index) in checkedTagsCopyForChips">
                    <div class="courses-filter__chips courses-filter__chips--chip">
                        <p>{{ tag }}</p>
                        <button @click="(event) => removeCheckedItem('tag', index)">
                            <img src="/theme/luxoft-2024/assets/images/icons/close-chip.svg" width="24" height="24" alt="close search icon">
                        </button>
                    </div>
                </template>

                <div v-show="checkedCategoriesCopyForChips.length > 0 || checkedTagsCopyForChips.length > 0" class="courses-filter__chips courses-filter__chips--chip counter-chip">
                    <button @click="clearFilters()" class="text-uppercase">
                        Clear all
                    </button>
                </div>
            </div>

            <div class="courses-filter__apply-btn-mobile mt-2">
                <button class="btn-normal text-uppercase" @click="applyFilters">Apply</button>
            </div>
        </div>

        <div
            class="mobile-filters-panel"
            id="mobile-filters-panel"
            ref="mobileFilters"
        >
            <div class="mobile-filters-panel__head">
                <p>Category</p>

                <button @click="closePanel">
                    <img src="/theme/luxoft-2024/assets/images/icons/close-chip.svg" alt="close icon">
                </button>
            </div>
            <div class="mobile-filters-panel__list checkbox-list">
                <div class="filter-search-results">
                    <ul>
                        <li v-for="(category, key) in allCategories">
                            <label :for="`${key}-${key}`">{{ category.title }}</label>
                            <input type="checkbox" :id="`${key}-${key}`" :value="category.title" v-model="checkedCategories">
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="checkedCategories.length > 0" class="mobile-filters-panel__close">
                <button @click="applyFilters" class="btn-normal">Apply</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import useCoursesFilterFns from '../functions/coursesFilter'

export default {
	props: {
		categories: {
			type: Object,
			required: true,
			default: () => [],
		}
	},
	setup(props) {
        const checkedCategories = ref([])
        const checkedTags = ref([])
		const allCategories = props.categories
        const bigTagsNames = ['Methodologies and Processes of Software Development']

		const {
            openMobileFilters,
            closePanel,
			getElementsFromUrl,
			removeCheckedItem,
			applyFilters,
			clearFilters,
			updatePaginationLinks,
			chipsToDisplay,
            checkedCategoriesCopyForChips,
            checkedTagsCopyForChips,
			keyword,
			keywordCopyForChip,
            overlayMobile,
            mobileFilters,
		} = useCoursesFilterFns(
            allCategories,
            checkedCategories,
            checkedTags
		)

		onMounted(() => {
			getElementsFromUrl()
			updatePaginationLinks()
		})

		return {
            openMobileFilters,
            closePanel,
			applyFilters,
			clearFilters,
			removeCheckedItem,
			chipsToDisplay,
			allCategories,
			checkedCategories,
            checkedCategoriesCopyForChips,
            checkedTags,
            checkedTagsCopyForChips,
			keyword,
			keywordCopyForChip,
            overlayMobile,
            mobileFilters,
            bigTagsNames
		}
	}
}
</script>
