import { createApp } from "vue/dist/vue.esm-bundler"
import {getCurrentPathComponent, routes} from "./config/router"
import "@splidejs/splide/css/core"

interface AppComponent {
    name: string | undefined;
    component: object | undefined;
}

const { name = undefined, component = undefined }: AppComponent = getCurrentPathComponent(routes)

if (name) {
    const app = createApp({})
    app.component(name, component)
    app.mount("#app")
}
