import { ref } from 'vue'

const checkedTopicsCopyForChips = ref([])
const keyword = ref('')
const keywordCopyForChip = ref('')
const mobileFilters = ref(null)
const overlayMobile = ref(null)

const useBlogFiltersFns = (
  allTopics,
  checkedTopics
) => {
  const openMobileFilters = (e) => {
    if (window.innerWidth <= 1023) {
      overlayMobile.value.classList.add('show')
      mobileFilters.value.classList.add('show')
    }
  }

  const closePanel = () => {
    overlayMobile.value.classList.remove('show')
    mobileFilters.value.classList.remove('show')
  }

  const areSimilar = (str1, str2) => {
    return str1.toLowerCase().trim().includes(str2.toLowerCase().trim()) || str2.toLowerCase().trim().includes(str1.toLowerCase().trim())
  }

  const setSelectedElementsFromUrl = (topics) => {
    let topicToSelect = ''
    for (const topic of topics) {
      if (topic === 'web') {
        topicToSelect = 'Web'
      }

      if (!topicToSelect) {
        for (const item of allTopics) {
          if (areSimilar(item, topic)) {
            topicToSelect = item
            break
          }
        }
      }

      checkedTopics.value = [...checkedTopics.value, topicToSelect]
      checkedTopicsCopyForChips.value = [...checkedTopicsCopyForChips.value, topicToSelect]
      topicToSelect = ''
    }
  }

  const getElementsFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    keyword.value = urlParams.get('keyword') || ''
    keywordCopyForChip.value = urlParams.get('keyword') || ''

    let topicsFromUrl = []
        const stringReplace = window.location.search.replace(/%20/g, ' ')
          .replace(/%23/g, '#')
          .replace(/%28/g, '(')
          .replace(/%29/g, ')')
          .replace(/%5b/g, '[')
          .replace(/%5d/g, ']')
          .replace(/%2f/g, '/')
          .replace(/%2c/g, ',')
          .replace(/%2e/g, '.')
          .replace(/%26/g, '&')

    if (stringReplace.includes('topic[]=')) {
        topicsFromUrl = getSubstring('&topic[]=', ['&page', '&utm'], stringReplace, '&topic[]=')
    }

    setSelectedElementsFromUrl(topicsFromUrl)
  }

  const getSubstring = (startStr, endStr, str, splitBy) => {
      let pos = str.indexOf(startStr) + startStr.length

      for (const param of endStr) {
          if (str.includes(param)) {
              return str.substring(pos, str.indexOf(param, pos)).split(splitBy)
          }
      }

      return str.substring(pos).split(splitBy)
  }

  const removeCheckedItem = (type, index) => {
    if (type === 'topic') {
      checkedTopics.value.splice(index, 1)
      checkedTopicsCopyForChips.value.splice(index, 1)
    }

    if (type === 'keyword') {
      keyword.value = ''
    }

    applyFilters()
  }

  const applyFilters = () => {
    let topicsUrl = ''

    topicsUrl = checkedTopics.value.map(item => {
      return `&topic[]=${item.replace(/,/g, '%2c').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\./g, '%2e').replace(/\&/g, '%26')}`
    }).join('')

    window.location.href = `/blog/?keyword=${keyword.value}${topicsUrl}`
  }

  const clearFilters = () => {
    window.location.href = '/blog'
  }

  const updatePaginationLinks = () => {
    const paginationLinks = document.getElementsByClassName('pagination__link')
    const urlParams = new URLSearchParams(window.location.search)
    const usePageSlashParam = urlParams.getAll('topic[]').length > 0 || (urlParams.size === 1 && window.location.search !== '?keyword=') 
      ? 0
      : 1

    if (paginationLinks.length > 0) {
      for (const pLink of paginationLinks) {
        if (pLink.getAttribute('href')) {
          const pageNumber = pLink?.textContent.trim()
          const isActive = pLink.classList.contains('active')
          pLink.toggleAttribute('href', !isActive)

          if (!isActive) {
            if (usePageSlashParam) {
              const newHref = pageNumber === '1' ? `${window.location.origin}/blog` : `${window.location.origin}/blog/page/${pageNumber}`;
              pLink.setAttribute('href', newHref)
            } else {
              const output = window.location.search.includes('&page=')
                            ? `${window.location.search.substring(0, window.location.search.lastIndexOf('&page='))}&page=${pageNumber}`
                            : window.location.search.includes('?page=')
                            ? `${window.location.search.substring(0, window.location.search.lastIndexOf('?page='))}?page=${pageNumber}`
                            : `${window.location.search}&page=${pageNumber}`
              
              const newHref = `${window.location.pathname}${output}`
              pLink.setAttribute('href', newHref)
            }
          }
        }
      }
    }
  }

  return {
    closePanel,
    getElementsFromUrl,
    removeCheckedItem,
    applyFilters,
    clearFilters,
    updatePaginationLinks,
    openMobileFilters,
    checkedTopics,
    checkedTopicsCopyForChips,
    overlayMobile,
    mobileFilters,
    keyword,
    keywordCopyForChip
  }
}

export default useBlogFiltersFns
