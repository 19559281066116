import {
    validate,
    VALIDATOR_REQUIRE,
    VALIDATOR_MINLENGTH,
    VALIDATOR_MAXLENGTH,
    VALIDATOR_ALPHABETIC,
    VALIDATOR_EMAIL, VALIDATOR_PHONE,
    VALIDATOR_ALPHABETIC_ALPHANUMERIC, VALIDATOR_ALPHABETIC_COUNT
} from '../utils/validators'

const contactUsForm: HTMLElement = document.getElementById('contact-us-form')
const submitBtn: HTMLElement = document.getElementById('standard_page_contact_us_form_submit')

if (contactUsForm && submitBtn) {
    let isValid: boolean = true
    const textFields: NodeListOf<Element> = contactUsForm.querySelectorAll('input[type="text"]')
    const numberFields: NodeListOf<Element> = contactUsForm.querySelectorAll('input[type="number"]')
    const emailField: NodeListOf<Element> = contactUsForm.querySelectorAll('input[type="email"]')
    const phoneField: NodeListOf<Element> = contactUsForm.querySelectorAll('input[type="tel"]')
    const messageField: NodeListOf<Element> = contactUsForm.querySelectorAll('textarea')

    const formValidation = {
        fieldRules: {
            standard_page_contact_us_form_name: {
                validators: [VALIDATOR_REQUIRE(), VALIDATOR_ALPHABETIC_COUNT(3), VALIDATOR_ALPHABETIC('Name'), VALIDATOR_MINLENGTH(3), VALIDATOR_MAXLENGTH(30)],
                is_required: true
            },
            standard_page_contact_us_form_company: {
                validators: [VALIDATOR_REQUIRE(), VALIDATOR_ALPHABETIC_COUNT(2), VALIDATOR_ALPHABETIC_ALPHANUMERIC(), VALIDATOR_MINLENGTH(2), VALIDATOR_MAXLENGTH(30)],
                is_required: true
            },
            standard_page_contact_us_form_email: {
                validators: [VALIDATOR_REQUIRE(), VALIDATOR_EMAIL(), VALIDATOR_MAXLENGTH(40)],
                is_required: true
            },
            standard_page_contact_us_form_phone: {
                validators: [VALIDATOR_PHONE(), VALIDATOR_MAXLENGTH(20)],
                is_required: false
            },
            standard_page_contact_us_form_message: {
                validators: [VALIDATOR_REQUIRE(), VALIDATOR_ALPHABETIC_COUNT(18) , VALIDATOR_MINLENGTH(20), VALIDATOR_MAXLENGTH(250)],
                is_required: true
            },
        },
        showErrorMessage(field: HTMLElement, message: string = ''): void {
            const errorContainer: HTMLElement = document.getElementById(`form-${field.id}-error`)
            if (errorContainer) {
                const previousSibling: HTMLElement = errorContainer.previousElementSibling as HTMLElement

                if (previousSibling) {
                    previousSibling.classList.add('with-error')
                }

                if (message) {
                    errorContainer.querySelector('p').textContent = message
                }

                errorContainer.classList.remove('hidden')
            }
        },
        hideErrorMessage(field: HTMLElement): void {
            const errorContainer: HTMLElement = document.getElementById(`form-${field.id}-error`)
            if (errorContainer) {
                errorContainer.classList.add('hidden')

                const previousSibling: HTMLElement = errorContainer.previousElementSibling as HTMLElement

                if (previousSibling) {
                    previousSibling.classList.remove('with-error')
                }
            }
        },
        validateInputs(allInputs: NodeListOf<Element>[], source: string | null = null): void {
            isValid = true
            const $that = this
            let validationResults: {} = {}
            let keyPressed: boolean = false

            for (const inputs of allInputs) {
                inputs.forEach(function (field: HTMLInputElement): void {
                    if (source === 'submit') {
                        const {
                            inputIsValid,
                            message
                        } = validate(field.value.trim(), $that.fieldRules[field.id].validators)
                        validationResults = {...validationResults, ...{[field.id]: inputIsValid}}

                        if (!inputIsValid) {
                            $that.showErrorMessage(field, message)
                        } else {
                            $that.hideErrorMessage(field)
                        }

                        if (!$that.fieldRules[field.id].is_required && field.value.trim() === '') {
                            $that.hideErrorMessage(field)
                            validationResults[field.id] = true
                        }

                        for (const [key, value] of Object.entries(validationResults)) {
                            if (!value) {
                                isValid = false
                            }
                        }
                    } else {
                        field.addEventListener('keyup', function (e: KeyboardEvent): void {
                            if (!keyPressed && e.key !== 'Tab') {
                                keyPressed = true

                                setTimeout(() => {
                                    const { inputIsValid, message} = validate(this.value.trim(), $that.fieldRules[field.id].validators)

                                    if (!inputIsValid) {
                                        $that.showErrorMessage(field, message)
                                    } else {
                                        $that.hideErrorMessage(field)
                                    }

                                    if (!$that.fieldRules[field.id].is_required && this.value.trim() === '') {
                                        $that.hideErrorMessage(field)
                                    }

                                    keyPressed = false
                                }, 1500)
                            }
                        })

                        field.addEventListener('blur', function (): void {
                            this.value = this.value.trim()

                            if ($that.fieldRules[field.id].is_required && this.value.trim() === '') {
                                $that.showErrorMessage(field, 'This field is required.')
                            }
                        })
                    }
                })
            }
        },
        init(): void {
            this.validateInputs([textFields, numberFields, emailField, phoneField, messageField])
        }
    }

    formValidation.init()

    if (submitBtn) {
        submitBtn.addEventListener('click', function (e: MouseEvent): void {
            formValidation.validateInputs([textFields, numberFields, emailField, phoneField, messageField], 'submit')

            if (!isValid) {
                e.preventDefault() // Prevent form submission if validation fails
            }
        })
    }
}
