import Splide, { PaginationData, PaginationItem } from "@splidejs/splide"

const corporateSolutionsDesktop: HTMLElement = document.getElementById('corporate-solutions-desktop')
const corporateSolutionsMobile: HTMLElement = document.getElementById('corporate-solutions-mobile')

if (
    corporateSolutionsDesktop &&
    corporateSolutionsMobile
) {
    const corporateSolutionsItems: NodeListOf<Element> = corporateSolutionsDesktop.querySelectorAll('.customized-corporate-solutions__items--single-item')
    const corporateSolutionsList: HTMLCollectionOf<Element> = corporateSolutionsMobile.getElementsByClassName('splide__list')

    if (corporateSolutionsItems.length > 0) {
        corporateSolutionsItems.forEach((speakerItem: Element): void => {
            corporateSolutionsList[0].append(speakerItem.cloneNode(true))
        })
    }

    setTimeout(function(): void {
        const corporateSolutionsSplide: NodeListOf<Element> = document.querySelectorAll("#corporate-solutions-mobile.splide")

        corporateSolutionsSplide.forEach((item: any): void => {
            const splide: Splide = new Splide(item, {
                type: 'loop',
                gap: "20px",
                drag: true,
                arrows: false,
                perMove: 1,
                perPage: 3,
                breakpoints: {
                    1023: {
                        perPage: 1
                    }
                }
            })

            splide.on("pagination:mounted", function (data: PaginationData): void {
                data.list.classList.add("splide__pagination--custom")

                data.items.forEach(function (item: PaginationItem): void {
                    item.button.classList.add("splide__pagination--custom__item")
                })
            })

            splide.mount()
        })
    }, 500)
}

const notesNotes: HTMLElement = document.getElementById('form-btn-collapse')
const explanatoryNotesContent: HTMLElement = document.getElementById('explanatory-notes-content')

if (
    notesNotes &&
    explanatoryNotesContent
) {
    notesNotes.addEventListener('click', function(e: MouseEvent ): void {
        e.preventDefault()
        this.classList.toggle('expanded')
        explanatoryNotesContent.classList.toggle('expanded')
    })
}


const whyLuxoftTrainingCenter: HTMLElement = document.getElementById('why-luxoft-training-center-splide')

if (whyLuxoftTrainingCenter) {
    const corporateSolutionsSplide: NodeListOf<Element> = document.querySelectorAll("#why-luxoft-training-center-splide.splide")

    corporateSolutionsSplide.forEach((item: any): void => {
        const splide: Splide = new Splide(item, {
            type: 'loop',
            gap: "20px",
            drag: true,
            arrows: true,
            perMove: 1,
            perPage: 4,
            breakpoints: {
                1023: {
                    perPage: 1
                },
                1439: {
                    perPage: 2
                }
            }
        })

        splide.on("pagination:mounted", function (data: PaginationData): void {
            data.list.classList.add("splide__pagination--custom")

            data.items.forEach(function (item: PaginationItem): void {
                item.button.classList.add("splide__pagination--custom__item")
            })
        })

        splide.mount()
    })
}

const trainingPartners: HTMLElement = document.getElementById('training-partners-companies')

if (trainingPartners) {
    const trainingPartnersSplide: NodeListOf<Element> = document.querySelectorAll("#training-partners-companies.splide")

    trainingPartnersSplide.forEach((item: any): void => {
        const splide: Splide = new Splide(item, {
            type: 'loop',
            gap: "20px",
            drag: true,
            arrows: false,
            perMove: 1,
            perPage: 6,
            breakpoints: {
                1023: {
                    perPage: 2
                },
                1439: {
                    perPage: 5
                }
            }
        })

        splide.on("pagination:mounted", function (data: PaginationData): void {
            data.list.classList.add("splide__pagination--custom")

            data.items.forEach(function (item: PaginationItem): void {
                item.button.classList.add("splide__pagination--custom__item")
            })
        })

        splide.mount()
    })
}

const relatedCourses: HTMLElement = document.getElementById('course-related-courses')

if (relatedCourses) {
    const relatedCoursesSplide: NodeListOf<Element> = document.querySelectorAll("#course-related-courses.splide")

    relatedCoursesSplide.forEach((item: any): void => {
        const splide: Splide = new Splide(item, {
            type: 'loop',
            gap: "20px",
            drag: true,
            arrows: false,
            perMove: 1,
            perPage: 1,
        })

        splide.on("pagination:mounted", function (data: PaginationData): void {
            data.list.classList.add("splide__pagination--custom")

            data.items.forEach(function (item: PaginationItem): void {
                item.button.classList.add("splide__pagination--custom__item")
            })
        })

        splide.mount()
    })
}
