<template>
    <div>
        <div
            ref="overlayMobile"
            class="overlay overlay-mobile"
            @click="closePanel"
        ></div>
        <div class="container blogs-filters-chips-wrapper">
            <div class="blogs-filters">
                <form @submit.prevent="applyFilters" class="blogs-filters__topics-form">
                    <div class="dropdown" :class="checkedTopics.length > 0 ? 'items-checked' : ''">
                        <button
                            class="dropdown-face body-m-regular text-medium-gray"
                            type="button"
                            id="topicsDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            @click="openMobileFilters"
                        >
                            Category
                            <img src="/theme/luxoft-2024/assets/images/icons/caret-down-black.svg" width="20" height="24" alt="caret down icon">
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="topicsDropdown">
                            <li v-for="(topic, key) in allTopics">
                                <label :for="key">{{ topic }}</label>
                                <input type="checkbox" :id="key" :value="topic" v-model="checkedTopics">
                            </li>
                        </ul>
                    </div>

                    <button type="submit" class="btn-normal text-uppercase">
                        Apply
                    </button>
                </form>
                <form class="blogs-filters__search-form" @submit.prevent="applyFilters">
                    <label for="search-word"></label>
                    <input type="text" placeholder="Search" name="search-word" v-model="keyword">
                    <button type="submit" class="btn-normal">
                        <img src="/theme/luxoft-2024/assets/images/icons/magnifier.svg" width="24" height="24" alt="search icon">
                    </button>
                </form>
            </div>

            <div class="blogs-filters__chips">
                <div
                    v-if="keywordCopyForChip"
                    class="blogs-filters__chips blogs-filters__chips--chip"
                >
                    <p>{{ keywordCopyForChip }}</p>
                    <button @click="removeCheckedItem('keyword')">
                        <img src="/theme/luxoft-2024/assets/images/icons/close-purple.svg" width="24" height="24" alt="close chip icon">
                    </button>
                </div>

                <template v-for="(topic, index) in checkedTopicsCopyForChips">
                    <div
                        class="blogs-filters__chips blogs-filters__chips--chip"
                        :class="{ 'bigger-tag': bigTagsNames.includes(topic) }"
                    >
                        <p :class="{ 'bigger-tag': bigTagsNames.includes(topic) }">{{ topic }}</p>
                        <button @click="(event) => removeCheckedItem('topic', index)">
                            <img src="/theme/luxoft-2024/assets/images/icons/close-purple.svg" width="24" height="24" alt="close search icon">
                        </button>
                    </div>
                </template>

                <div v-show="checkedTopicsCopyForChips.length > 0" class="blogs-filters__chips blogs-filters__chips--chip counter-chip">
                    <button @click="clearFilters()" class="text-uppercase">
                        Clear all
                    </button>
                </div>
            </div>

            <div class="blogs-filters__apply-btn-mobile mt-2">
                <button class="btn-normal text-uppercase" @click="applyFilters">Apply</button>
            </div>
        </div>

        <div
            class="mobile-filters-panel"
            id="mobile-filters-panel"
            ref="mobileFilters"
        >
            <div class="mobile-filters-panel__head">
                <p>Category</p>

                <button @click="closePanel">
                    <img src="/theme/luxoft-2024/assets/images/icons/close-chip.svg" alt="close icon">
                </button>
            </div>
            <div class="mobile-filters-panel__list checkbox-list">
                <div class="filter-search-results">
                    <ul>
                        <li v-for="(category, key) in allTopics">
                            <label :for="`${key}-${key}`">{{ category }}</label>
                            <input type="checkbox" :id="`${key}-${key}`" :value="category" v-model="checkedTopics">
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="checkedTopics.length > 0" class="mobile-filters-panel__close">
                <button @click="applyFilters" class="btn-normal">Apply</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import useBlogFiltersFns from '../functions/blogsFilters'

export default {
    props: {
        topics: {
            type: Array,
            required: true,
            default: () => [],
        }
    },
    setup(props) {
        const checkedTopics = ref([])
        const allTopics = props.topics
        const bigTagsNames = ['Methodologies and Processes of Software Development']

        const {
            getElementsFromUrl,
            removeCheckedItem,
            applyFilters,
            clearFilters,
            updatePaginationLinks,
            closePanel,
            openMobileFilters,
            chipsToDisplay,
            overlayMobile,
            mobileFilters,
            checkedTopicsCopyForChips,
            keyword,
            keywordCopyForChip,
        } = useBlogFiltersFns(
            allTopics,
            checkedTopics
        )

        onMounted(() => {
            getElementsFromUrl()
            updatePaginationLinks()
        })

        return {
            closePanel,
            openMobileFilters,
            applyFilters,
            clearFilters,
            removeCheckedItem,
            overlayMobile,
            mobileFilters,
            chipsToDisplay,
            allTopics,
            checkedTopics,
            checkedTopicsCopyForChips,
            keyword,
            keywordCopyForChip,
            bigTagsNames
        }
    }
}
</script>
