import { enableShareSocialBtns } from './shared/utils'
import Splide, { PaginationData, PaginationItem } from "@splidejs/splide"

enableShareSocialBtns()

const blogUpcomingCourses: HTMLElement = document.getElementById('blog-upcoming-courses')
const blogSpliderUpcomingCourses: HTMLElement = document.getElementById('blog-splider-upcoming-courses')

if (
    blogUpcomingCourses &&
    blogSpliderUpcomingCourses
) {
    const coursesItems: NodeListOf<Element> = blogUpcomingCourses.querySelectorAll('.single-course')
    const mobileUpcomingList: HTMLCollectionOf<Element> = document.getElementsByClassName('splide__list')

    if (coursesItems.length > 0) {
        coursesItems.forEach((speakerItem: Element): void => {
            mobileUpcomingList[0].append(speakerItem.cloneNode(true))
        })
    }

    setTimeout(function (): void {
        const blogSplider: NodeListOf<Element> = document.querySelectorAll("#blog-splider-upcoming-courses.splide")
        const blogSpliderCount: number = coursesItems.length
        blogSplider.forEach((item: any): void => {
            const splide: Splide = new Splide(item, {
                type: blogSpliderCount > 3 ? 'loop' : 'slide',
                clones: blogSpliderCount > 3 ? 1 : 0,
                gap: "20px",
                drag: true,
                arrows: false,
                perMove: 1,
                perPage: blogSpliderCount > 1 ? 3 : 1,
                breakpoints: {
                    1023: {
                        perPage: 1
                    }
                }
            })

            splide.on("pagination:mounted", function (data: PaginationData): void {
                data.list.classList.add("splide__pagination--custom")

                data.items.forEach(function (item: PaginationItem): void {
                    item.button.classList.add("splide__pagination--custom__item")
                })
            })

            splide.mount()
        })
    }, 500)
}
