import { ref } from 'vue'

const checkedCategoriesCopyForChips = ref([])
const checkedTagsCopyForChips = ref([])
const keyword = ref('')
const keywordCopyForChip = ref('')
const mobileFilters = ref(null)
const overlayMobile = ref(null)

const useCoursesFilterFns = (allCategories, checkedCategories, checkedTags) => {
  const openMobileFilters = (e) => {
    if (window.innerWidth <= 1023) {
      overlayMobile.value.classList.add('show')
      mobileFilters.value.classList.add('show')
    }
  }

  const closePanel = () => {
    overlayMobile.value.classList.remove('show')
    mobileFilters.value.classList.remove('show')
  }

  const areSimilar = (str1, str2) => {
    return str1.toLowerCase().trim().includes(str2.toLowerCase().trim()) || str2.toLowerCase().trim().includes(str1.toLowerCase().trim())
  }

  // Set selected categories from the URL to the reactive variables
  const setSelectedElementsFromUrl = (categories) => {
    let categoryToSelect = ''
    for (const category of categories) {
      if (category === 'web') {
        categoryToSelect = 'Web'
      }

      if (!categoryToSelect) {
        for (const item of allCategories) {
          if (areSimilar(item.title, category)) {
            categoryToSelect = item.title
            break
          }
        }
      }
      
      checkedCategories.value = [...checkedCategories.value, categoryToSelect]
      checkedCategoriesCopyForChips.value = [...checkedCategoriesCopyForChips.value, categoryToSelect]
      categoryToSelect = ''
    }
  }

  // Set selected tags from the URL to the reactive variables
  const setSelectedTagsFromUrl = (tags) => {
    for (const tag of tags) {
      setTimeout(() => {
        // Add each tag to the reactive variables
        checkedTags.value = [...checkedTags.value, tag]
        checkedTagsCopyForChips.value = [...checkedTagsCopyForChips.value, tag]
      }, 150)
    }
  }

  // Extract elements like categories, tags, and keyword from the URL
  const getElementsFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)

    // Get keyword from URL
    keyword.value = urlParams.get('keyword') || ''
    keywordCopyForChip.value = urlParams.get('keyword') || ''

    // Extract categories if present in the URL
    let categoriesFromUrl = urlParams.getAll('category[]')
    if (categoriesFromUrl?.length > 0) {
      setSelectedElementsFromUrl(categoriesFromUrl)
    }

    // Extract tags if present in the URL
    let tagsFromUrl = urlParams.getAll('tags[]')
    if (tagsFromUrl?.length > 0) {
      setSelectedTagsFromUrl(tagsFromUrl)
    }
  }

  // Remove a selected item (category, tag, or keyword)
  const removeCheckedItem = (type, index) => {
    if (type === 'category') {
      // Remove category at the specified index
      checkedCategories.value.splice(index, 1)
      checkedCategoriesCopyForChips.value.splice(index, 1)
    }

    if (type === 'tag') {
      // Remove tag at the specified index
      checkedTags.value.splice(index, 1)
      checkedTagsCopyForChips.value.splice(index, 1)
    }

    if (type === 'keyword') {
      // Clear the keyword filter
      keyword.value = ''
    }

    applyFilters()
  }

  // Apply the selected filters by updating the URL
  const applyFilters = () => {
    let categoriesUrl = checkedCategories.value.map(item => {
      return `&category[]=${item.replace(/,/g, '%2c').replace(/\(/g, '%28').replace(/\)/g, '%29')}`
    }).join('')

    let tagsUrl = checkedTags.value.map(item => {
      return `&tags[]=${item.replace(/,/g, '%2c').replace(/\(/g, '%28').replace(/\)/g, '%29')}`
    }).join('')

    // Update the page URL with the selected filters
    window.location.href = `/it-courses?keyword=${keyword.value}${categoriesUrl}${tagsUrl}`
  }

  // Clear all filters and reset the page
  const clearFilters = () => {
    window.location.href = '/it-courses'
  }

  // Update pagination links with the current filters
  const updatePaginationLinks = () => {
    const paginationLinks = document.getElementsByClassName('pagination__link')
    const urlParams = new URLSearchParams(window.location.search)
    const usePageSlashParam = urlParams.getAll('category[]').length > 0 || (urlParams.size === 1 && window.location.search !== '?keyword=') 
      ? 0
      : 1

    if (paginationLinks.length > 0) {
      for (const pLink of paginationLinks) {
        if (pLink.getAttribute('href')) {
          const pageNumber = pLink?.textContent.trim()
          const isActive = pLink.classList.contains('active')
          pLink.toggleAttribute('href', !isActive)

          if (!isActive) {
            if (usePageSlashParam) {
              const newHref = pageNumber === '1' ? `${window.location.origin}/it-courses` : `${window.location.origin}/it-courses/page/${pageNumber}`;
              pLink.setAttribute('href', newHref)
            } else {
              const output = window.location.search.includes('&page=')
                            ? `${window.location.search.substring(0, window.location.search.lastIndexOf('&page='))}&page=${pageNumber}`
                            : window.location.search.includes('?page=')
                            ? `${window.location.search.substring(0, window.location.search.lastIndexOf('?page='))}?page=${pageNumber}`
                            : `${window.location.search}&page=${pageNumber}`
              
              const newHref = `${window.location.pathname}${output}`
              pLink.setAttribute('href', newHref)
            }
          }
        }
      }
    }
  }

  // Return the exposed functions and reactive variables
  return {
    openMobileFilters,
    closePanel,
    getElementsFromUrl,
    removeCheckedItem,
    applyFilters,
    clearFilters,
    updatePaginationLinks,
    checkedCategories,
    checkedCategoriesCopyForChips,
    checkedTags,
    checkedTagsCopyForChips,
    keyword,
    keywordCopyForChip,
    overlayMobile,
    mobileFilters,
  }
}

export default useCoursesFilterFns
