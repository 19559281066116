
interface FormSubmission {
    formData: any;  // Уточните тип в зависимости от структуры formData
    formName: string;
    formPosition: string;
    result: string;
}
declare var dataLayer: Array<any>;

function handleFormSubmission(event: CustomEvent<FormSubmission>) {
    const { formData, formName, formPosition, result } = event.detail;
    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            'event': 'from_submition',
            'form_name': formName,
            'form_data': {
                'record_id': formData.record_id,
                'record_title': formData.record_title,
                'record_code': formData.record_code,
                'record_price': formData.record_price
            },
            'form_position': formPosition,
            'result': result,
        });
    }
}

function scrollToResult(event) {
    const { formResultNodeId } = event.detail;
    if (formResultNodeId) {
        document.getElementById(formResultNodeId)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }
}

document.addEventListener('formSubmition', handleFormSubmission as EventListener);
document.addEventListener('formSubmition', scrollToResult as EventListener);
