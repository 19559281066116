
import Splide, {PaginationData} from "@splidejs/splide"

function initializeInfoBannerSplide(carouselElement): void {
  const splide: Splide = new Splide(carouselElement, {
    fixedWidth: "90%",
    height: "140px",
    gap: "15px",
    drag: true,
    arrows: false,
  })

  splide.on("pagination:mounted", function (data: PaginationData): void {
    data.list.classList.add("splide__pagination--custom")

    data.items.forEach(function (item): void {
      item.button.classList.add("splide__pagination--custom__item")
    })
  })

  splide.on("ready", function (): void {
    // Fixes Safari problem with svg rendering
    const svgsInCarousel = carouselElement.querySelectorAll('svg')
    svgsInCarousel.forEach((svg): void => {
      // Toggle the 'display' property to trigger a redraw
      svg.style.display = 'none'
      setTimeout((): void => {
        svg.style.display = 'block'
      }, 100)
    })
  })

  splide.mount()
}

const infoBannerSplide: HTMLElement = document.getElementById('splider-about-us-info-banner')

if (infoBannerSplide) {
  initializeInfoBannerSplide(infoBannerSplide)
}
